.Search-form {
}
.SearchResult-visitors {
    font-size: 2.5rem;
    margin-bottom: 10px;
}
.SearchResult-visitors > span {
    display: inline-block;
}
.SearchResult-visitorsCurrent {
    font-size: 3.0rem;
}
.SearchResult-visitorsMax {
    font-size: 2rem;
    transform: translateY(5px);
}
.SearchResult-visitorsDivider {
    font-size: 2rem;
}
